<template>
  <div class="choice-reprint-container">
    <header>
      <van-nav-bar
          title="添加视频"
          left-arrow
          @click-left="handleBlack"
          safe-area-inset-top
      >
      </van-nav-bar>
      </header>
    <article>
      <div class="select-reprint-item" @click="handleToCreate('reprint')">
        <icon-svg iconClass="iconshare-reprint-copy"/><span>转载优质视频</span>
      </div>
      <div class="select-reprint-item" @click="handleToCreate('original')">
        <icon-svg iconClass="iconedit-3-copy"/><span>添加原创视频</span>
      </div>
    </article>
  </div>
</template>

<script>
import {useRouter} from "vue-router";

export default {
  name: "selectCreateType",
  setup(){
    const router = useRouter()
    const handleBlack=()=>{
      router.push('/videoVisitors')
    }
    const handleToCreate=(val)=>{
      switch (val){
        case "reprint":
          router.push({
            path:'/video/reprintVideo'
          })
          break;
        case "original":
          router.push({
            path: "/video/createVideo"
          })
          break
        default:
          break
      }
    }
    return{
      handleBlack,
      handleToCreate,
    }
  }
}
</script>
<style lang="scss">
.choice-reprint-container{
  .van-nav-bar__title {
    color: #333333;
    font-weight: 600;
  }
  .van-nav-bar__arrow {
    color: #333333;
  }
}

</style>
<style scoped lang="scss">
.choice-reprint-container{
  display: flex;
  height: 100vh;
  flex-direction: column;
  article{
    flex: 1;
    padding: 16px;
    background-color: #F1F3F6;
    .select-reprint-item{
      padding: 16px;
      text-align: left;
      //height: 56px;
      background-color: #FFFFFF;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      box-shadow: 0px 1px 2px rgba(151, 151, 151, 0.1);
      border-radius: 6px;
      span{
        margin-left: 12px;
        font-size: 14px;
      }
    }
  }
}
</style>
